@font-face {
  font-family: "century_gothicregular";
  src: url("../fonts/gothic-webfont.woff2") format("woff2"),
    url("../fonts/gothic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "century_gothicbold";
  src: url("../fonts/gothicb-webfont.woff2") format("woff2"),
    url("../fonts/gothicb-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --bs-font-sans-serif: "century_gothicbold";
  --bs-body-font-family: "century_gothicregular";
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: normal;
  --bs-body-line-height: 1.5;
  --bs-body-color: #000000;
  --bs-body-bg: #fff;
  --bs-primary: #ecbf4c;
  --bs-primary-rgb: 246, 213, 10;
  --bs-secondary: #000000;
  --bs-secondary-rgb: 0, 0, 0;
  --border-radius: 0.375rem;
  --shadow-rgba: rgba(0, 0, 0, 0.15);
  --bs-white: #fff;
}

body {
  color: var(--bs-body-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bs-body-bg);
  font-family: var(--bs-body-font-family);
}

.link,
.link:hover,
.link:focus {
  color: var(--bs-primary);
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
.header .middle-content p,
.content-img-details h3,
.connected-account,
.navbar-nav .nav-link,
.web3nav h1 span {
  font-family: var(--bs-font-sans-serif);
  letter-spacing: 0.0625rem;
  margin: 0;
}

.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.header {
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
}

.middle-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.header figure {
  margin: 0;
}

.header img {
  width: 100%;
  height: auto;
}

.header .middle-content h1 {
  font-size: 4.0625rem;
  line-height: 1;
  margin-bottom: 0.625rem;
}

.header .middle-content h1 span {
  font-family: var(--bs-body-font-family);
  font-size: 1.875rem;
  margin-bottom: 0.625rem;
  display: block;
}

.header .middle-content .datestyle {
  color: var(--bs-primary);
  font-size: 2.5rem;
  font-family: "Comforter Brush" !important;
  display: block;
  margin-bottom: 0rem;
  letter-spacing: normal;
}

.header .middle-content h3 {
  font-size: 2.125rem;
  line-height: 1;
  background-color: #c42d97;
  padding: 0.625rem 1.875rem;
  display: inline-block;
  border-radius: var(--border-radius);
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.header .middle-content h3 span {
  font-family: var(--bs-body-font-family);
}

.header .middle-content p {
  margin-bottom: 0.9375rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  display: block;
  font-size: 0.9375rem;
}

.header .middle-content .pay-card {
  width: auto;
  height: 2.5rem;
  margin: auto;
  display: block;
  margin-bottom: 0.9375rem;
}

.header .middle-content h4 {
  font-size: 1.25rem;
  margin-top: 9.375rem;
}

.logos-sec {
  margin-top: 1.25rem;
  margin-bottom: -13.625rem;
}

.logos-sec img {
  width: auto;
  height: 5.625rem;
  filter: drop-shadow(0rem 0.5rem 0.75rem black);
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}

.web3nav {
  background-image: url(../images/bg-color.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  display: block;
  position: relative;
  padding: 3.125rem 0;
}

.web3nav h1 {
  font-size: 2.8125rem;
  font-family: var(--bs-body-font-family);
  color: #fff;
  width: 63%;
  margin: auto;
}

.sec-w {
  position: relative;
  padding: 3.125rem 0;
}

.sec-w h2 {
  font-size: 2.25rem;
  margin-bottom: 0.9375rem;
}

.sec-w h2 span {
  color: var(--bs-primary);
}

.sec-w h4 {
  font-size: 1.5625rem;
}

.sec-w .met-img {
  padding-right: 1.875rem;
}

.sec-images,
.faq-sec {
  position: relative;
  padding: 3.125rem 0;
  background-color: var(--bs-secondary);
  color: #fff;
}

.sec-images h2,
.faq-sec h2 {
  font-size: 2.25rem;
  margin-bottom: 0.9375rem;
  color: #e81395;
  text-align: center;
  margin: auto;
  margin-bottom: 1.875rem;
}

.sec-images h2 span,
.faq-sec h2 span {
  color: #fff;
  font-family: var(--bs-body-font-family);
}

.sec-images .nft-img {
  border-radius: 0.625rem;
}

.sec-images .container-fluid {
  width: 90%;
}

.content-img {
  position: relative;
  max-width: 25rem;
  margin: auto;
  overflow: hidden;
  margin-bottom: 1.875rem;
}

.content-img .content-img-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content-img:hover .content-img-overlay {
  opacity: 1;
}

.content-img-image {
  width: 100%;
  border-radius: 0.625rem;
}

.content-img-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content-img:hover .content-img-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-img-details a {
  text-decoration: none;
}

.content-img-details h3 {
  color: #fff;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-size: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.content-img-details p {
  color: #fff;
  font-size: 0.8125rem;
}

.fadeIn-bottom {
  top: 80%;
}

.content-img .content-img-details a,
.content-img .content-img-details a:hover {
  color: #fff;
  margin: 0.5rem;
}

.content-img .content-img-details svg {
  width: 1.5rem;
  height: 1.5rem;
}

.faq-sec {
  width: 100%;
  position: relative;
  padding: 3.75rem 0 3.75rem 0;
}

.faq-sec p {
  font-size: 1rem;
  margin-bottom: 0.9375rem;
}

.faq-sec .accordion-item {
  border: none;
  color: var(--bs-white);
  background: transparent;
}

.faq-sec .accordion-button {
  font-size: 1.25rem;
  border-bottom: solid 0.0625rem #868686;
  outline: none !important;
  box-shadow: none !important;
  color: var(--bs-white);
  background-color: transparent;
  font-family: var(--bs-font-menu);
  padding-left: 0;
  padding-right: 0;
}

.faq-sec .accordion-body {
  padding: 0.9375rem 0;
  font-size: 0.875rem;
  color: #cacaca;
}

.faq-sec .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e81395'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.sponsored-sec {
  position: relative;
  padding: 3.125rem 0 1.875rem 0;
}

.sponsored-sec h2 {
  font-size: 2.25rem;
  text-align: center;
  margin: auto;
  display: block;
  color: var(--bs-primary);
  margin-bottom: 1.875rem;
}

.sponsored-sec h2 span {
  color: var(--bs-secondary);
  font-family: var(--bs-body-font-family);
}

.sponsored-sec .sp-img {
  margin-bottom: 1.875rem;
}

footer {
  background-image: url(../images/bg-color.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  padding: 3.125rem 0;
  padding: 1.875rem 0rem 1.875rem 0rem;
  font-size: 0.8125rem;
  color: #fff;
}

footer .f-links {
  display: block;
  color: #fff;
  margin-top: 0.9375rem;
  margin-bottom: 1.5625rem;
}

footer .f-links a {
  font-size: 1rem;
  margin: 0 0.75rem;
  color: #fff;
  text-decoration: none;
}

footer .f-links a svg {
  width: 1.5rem;
  height: 1.5rem;
}

footer .f-links a:hover {
  color: var(--bs-primary);
}

.navbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navbar-nav {
  margin-left: auto;
  padding-top: 1.25rem;
  padding-right: 1.25rem;
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  width: auto;
  height: 11.5625rem;
  transition: all 0.5s ease;
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  z-index: 3;
}

.navbar-nav .nav-link {
  color: #fff;
  margin: 0 0.75rem;
  position: relative;
  padding: 0.625rem 0.625rem;
  font-size: 1rem;
  transition: all 0.5s ease;
}

.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover {
  color: var(--bs-primary);
}

.navbar-nav .nav-link:active:after,
.navbar-nav .nav-link:focus:after,
.navbar-nav .nav-link:hover:after {
  width: 100%;
  -webkit-transition: all 0.1s ease;
  transition: all 0.5s ease;
}

.navbar-nav .nav-link:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.navbar-nav .nav-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  height: 0.125rem;
  -webkit-transition: 0.35s;
  transition: 0.35s;
  background-color: #e81395;
}

.navbar-nav .nav-link:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link,
.navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link:hover {
  background-color: #e81395;
  color: #fff;
  padding: 0.625rem 0.9375rem;
  border-radius: var(--border-radius) !important;
  border: none;
  margin-right: 0;
}

.navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link:after,
.navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link::before {
  display: none;
}

.navbar-toggler {
  background-color: #e81395;
  color: #fff;
  padding: 0.1875rem 0.375rem;
  border-end-end-radius: var(--border-radius);
  margin-right: 0.625rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none;
}

.navbar-toggler svg {
  width: 1.75rem;
  height: 1.75rem;
}

.sc-down .navbar-brand img {
  opacity: 0;
  transition: all 1s ease;
}

.sc-up .navbar {
  background-color: rgba(0, 0, 0, 0.85);

  position: fixed;
}

.sc-up .navbar-brand img {
  height: 2.8125rem;
  top: 0.3125rem;
  opacity: 1;
  transition: all 0.01s ease;
}

.sc-up .navbar-nav .nav-link {
  font-size: 0.875rem;
  transition: all 0.5s ease;
}

.sc-up .navbar-nav {
  padding-top: 0;
}

.btn,
.btn:hover,
.btn:focus,
.btn:active {
  border-radius: 0.375rem;
  outline: none !important;
  box-shadow: none !important;
}

.btn-primary,
.btn {
  font-size: 1rem;
  font-family: var(--bs-font-sans-serif);
  padding: 0.9375rem 1.875rem;
  line-height: 1;
  background-color: var(--bs-primary);
  border: solid 0.0625rem var(--bs-secondary-rgb);
  color: var(--bs-secondary);
}

.btn-primary:hover,
.btn-primary:focus {
  border: solid 0.0625rem var(--bs-secondary-rgb);
  background-color: var(--bs-secondary);
}

.btn-secondary {
  background-color: var(--bs-secondary);
  color: #fff;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: var(--bs-primary);
  color: var(--bs-secondary);
}

img {
  width: 100%;
  height: auto;
}

.align-items-center {
  align-items: center !important;
}

.form-label {
  margin-bottom: 0.625rem;
  color: #9f9f9f;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.form-control,
.form-control:focus,
.form-select {
  outline: none !important;
  box-shadow: none !important;
  color: var(--bs-secondary);
  background-color: transparent;
  border: 0.0625rem solid #f8f8f8;
  font-family: var(--bs-body-font-family);
  border-radius: 0rem;
  resize: none;
  border-bottom: solid 0.125rem rgb(167, 167, 167);
  padding-left: 0;
  box-shadow: non;
  font-size: 0.875rem;
}

.form-control:focus,
.form-select:focus {
  border: 0.0625rem solid #f8f8f8 !important;
  border-bottom: solid 0.125rem var(--bs-primary) !important;
  box-shadow: non;
}

::placeholder {
  color: #7d7d7d !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #7d7d7d !important;
}

::-ms-input-placeholder {
  color: #7d7d7d !important;
}

.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--shadow-rgba);
}

.check-input .form-check-input {
  width: 1.2em;
  height: 1.2em;
  background-color: #000;
  border: 0.0625rem solid #fff;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.check-input .form-check-input:checked {
  background-color: #000;
  border-color: #000;
  border: 0.0625rem solid var(--bs-primary);
}

.form-check-input {
  width: 1.2em;
  height: 1.2em;
  background-color: #fff;
  border: 0.0625rem solid #676767;
  box-shadow: none !important;
}

.form-check-label {
  padding-left: 0.3125rem;
  padding-top: 0.125rem;
}

.event-card {
  border: solid 0.0625rem #e81395;
  border-radius: 0.75rem;
  margin-bottom: 1.875rem;
}

.event-card .heading {
  font-size: 1rem;
  background-color: #e81395;
  padding: 0.625rem 0.9375rem;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.event-card .body {
  padding: 0.9375rem;
  color: #fff;
  font-size: 0.875rem;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.connected-account {
  position: absolute;
  top: 5.625rem;
  right: 1.25rem;
  text-align: right;
  font-size: 0.8125rem;
  z-index: 5;
  letter-spacing: normal;
}

.spinner-border {
  width: 1rem;
  height: 1rem;
  border: 0.15em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  margin-left: 0.3125rem;
}

.btn-show,
.btn-show:focus,
.btn-show:hover {
  font-size: 0.875rem;
  background-color: #e81395;
  color: #fff;
  margin-top: 1.25rem;
}

@media (max-width: 1680px) {
  .middle-content .container {
    padding-top: 3.125rem;
  }

  .logos-sec {
    margin-top: 1.25rem;
    margin-bottom: -7.625rem;
  }
}

@media (max-width: 1440px) {
  .sec-images .container-fluid {
    width: 100%;
  }

  .header .middle-content h4 {
    margin-top: 5rem;
  }

  .logos-sec {
    margin-bottom: -4rem;
  }
}

@media (max-width: 1366px) {
  .header .middle-content h1 span {
    font-size: 1.75rem;
  }

  .header .middle-content h1 {
    font-size: 3.75rem;
  }

  .web3nav h1 {
    font-size: 2.5rem;
    width: 60%;
  }

  .header .middle-content .datestyle {
    font-size: 2.25rem;
  }

  .header .middle-content h3 {
    font-size: 2rem;
    padding: 0.5rem 1.5625rem;
  }

  .navbar-nav .nav-link {
    margin: 0 0.5rem;
  }

  .navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link,
  .navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link:hover {
    padding: 0.3125rem 0.875rem;
    margin-top: 0.3125rem;
  }

  .navbar-brand img {
    height: 10rem;
  }

  .connected-account {
    top: 4.75rem;
    right: 1.25rem;
  }

  .logos-sec img {
    height: 5.3125rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .content-img-details h3 {
    font-size: 1.125rem;
  }
}

@media (max-width: 1280px) {
  .header .middle-content h4 {
    margin-top: 3.5rem;
  }
}

@media (max-width: 1024px) {
  .content-img-details h3 {
    font-size: 0.875rem;
  }

  .content-img-details p {
    font-size: 0.6875rem;
    display: none;
  }

  .content-img .content-img-overlay {
    height: 4.375rem;
    width: 100%;
    top: auto;
    bottom: 0;
    opacity: 1;
  }

  .content-img .fadeIn-bottom {
    opacity: 1;
    top: auto;
    bottom: -0.875rem;
  }

  .content-img:hover .content-img-details {
    top: auto;
    left: 50%;
    opacity: 1;
    bottom: -0.875rem;
  }

  .content-img .content-img-details svg {
    width: 1.375rem;
    height: 1.375rem;
  }

  .logos-sec img {
    height: 4.375rem;
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }

  .navbar-nav .nav-link {
    font-size: 0.875rem;
  }

  .header .middle-content h1 {
    font-size: 3.4375rem;
  }

  .header .middle-content .datestyle {
    font-size: 2rem;
  }

  .navbar-brand img {
    height: 9.375rem;
  }

  .header .middle-content h3 {
    font-size: 1.75rem;
  }

  .web3nav h1 {
    font-size: 2.1875rem;
    width: 60%;
  }

  .sec-w h2,
  .sec-images h2,
  .faq-sec h2,
  .sponsored-sec h2 {
    font-size: 1.875rem;
  }

  .sec-w .met-img {
    padding-right: 0.9375rem;
  }

  p {
    font-size: 0.875rem;
  }

  .faq-sec .accordion-button {
    font-size: 1rem;
  }
}

@media (max-width: 800px) {
  .connected-account {
    top: 4.5rem;
    right: 1.25rem;
    font-size: 0.75rem;
  }

  .navbar-expand-lg .navbar-nav .nav-item {
  }

  .navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link,
  .navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link:hover {
    display: inline-block;
  }

  .navbar-expand-lg .navbar-nav .nav-item + .nav-item {
    border-top: solid 0.0625rem #282828;
  }

  .navbar-brand img {
    height: 8.125rem;
    top: 0.625rem;
    left: 0.625rem;
  }

  .none-sc .navbar {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .none-sc .navbar-brand img {
    height: 3.125rem;
  }

  .web3nav h1 {
    font-size: 1.875rem;
    width: 100%;
  }

  .logos-sec img {
    height: 3.4375rem;
  }

  .navbar-nav .nav-link {
    font-size: 1.125rem;
  }

  .sc-up .navbar-nav .nav-link {
    font-size: 1.25rem;
  }

  .header .middle-content h4 {
    margin-top: 2.5rem;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 3.75rem 0;
    background-color: #000;
  }

  .header .middle-content h1 {
    font-size: 2.5rem;
  }

  .header .middle-content h1 span {
    font-size: 1.375rem;
  }

  .header .middle-content .datestyle {
    font-size: 1.625rem;
  }

  .header .middle-content h3 {
    font-size: 1.375rem;
    padding: 0.375rem 1.25rem;
  }

  .header .middle-content p {
    font-size: 0.875rem;
  }

  header .middle-content .pay-card {
    height: 2.1875rem;
    margin-bottom: 0.625rem;
  }

  .header .middle-content h4 {
    font-size: 1.125rem;
    margin-top: 2.5rem;
  }

  .logos-sec img {
    height: 3.125rem;
  }

  .web3nav h1 {
    font-size: 1.5625rem;
  }

  .sec-w .met-img img {
    margin-top: 1.875rem;
    margin-left: auto;
    margin-right: auto;
  }

  .row-reverse640 {
    flex-wrap: wrap-reverse;
  }

  .sec-w h2,
  .sec-images h2,
  .faq-sec h2,
  .sponsored-sec h2 {
    font-size: 1.5625rem;
  }

  .sec-w h4 {
    font-size: 1.25rem;
  }

  .sec-images h2,
  .faq-sec h2 {
    margin-bottom: 0.9375rem;
  }

  .navbar-brand img {
    height: 6.25rem;
  }

  .navbar {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }

  .navbar-nav {
    margin-left: auto;
    margin-right: auto;
  }

  .navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link,
  .navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link:hover {
    margin-top: 0.9375rem;
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 480px) {
  .navbar-brand img {
    height: 5rem;
  }

  .header {
    padding: 0;
  }

  .middle-content .container {
    padding-top: 1.25rem;
  }

  .navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link,
  .navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link:hover {
    text-align: center;
    width: 100%;
  }

  .navbar-toggler svg {
    width: 1.5625rem;
    height: 1.5625rem;
  }

  .faq-sec .accordion-button {
    font-size: 0.875rem;
  }

  .faq-sec .accordion-body {
    font-size: 0.8125rem;
  }

  .web3nav,
  .sec-w,
  .sec-images,
  .faq-sec,
  .sponsored-sec {
    padding: 1.875rem 0;
  }

  .sponsored-sec {
    padding-bottom: 0.625rem;
  }

  .sc-up .navbar-brand img {
    height: 2.375rem;
  }

  .content-img-details h3 {
    font-size: 0.6875rem;
  }

  .content-img .content-img-details svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  /* .content-img .fadeIn-bottom {
    bottom: -1.8125rem;
  } */

  .content-img .content-img-overlay {
    height: -0.875rem;
  }
}

@media (max-width: 425px) {
  .connected-account {
    top: 4.0625rem;
    right: 0.9375rem;
    font-size: 0.6875rem;
  }

  .event-card .heading {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }

  .event-card .body {
    padding: 0.625rem;
    font-size: 0.8125rem;
  }

  .header .middle-content .pay-card {
    height: 2.1875rem;
  }

  .sec-w h2,
  .sec-images h2,
  .faq-sec h2,
  .sponsored-sec h2 {
    font-size: 1.375rem;
  }

  .sec-w h4 {
    font-size: 1.125rem;
  }

  .logos-sec img {
    height: 3.125rem;
  }
}

@media (max-width: 375px) {
  .header .middle-content h1 span {
    font-size: 1.25rem;
  }

  .header .middle-content h1 {
    font-size: 2.25rem;
  }

  .header .middle-content h3 {
    font-size: 1.25rem;
    padding: 0.375rem 1.125rem;
  }

  .header .middle-content h4 {
    font-size: 1rem;
  }

  .web3nav h1 {
    font-size: 1.375rem;
  }

  footer .f-links a svg {
    width: 1.375rem;
    height: 1.375rem;
  }

  footer {
    padding: 1.25rem 0;
  }

  footer .f-links {
    margin-top: 0.5rem;
    margin-bottom: 1.25rem;
  }

  .navbar-brand img {
    height: 4.375rem;
  }

  .header .middle-content h4 {
    margin-top: 1.5625rem;
  }

  .logos-sec {
    margin-bottom: -4.375rem;
  }

  .logos-sec img {
    height: 2.625rem;
  }
}

@media (max-width: 320px) {
  .header .middle-content h1 span {
    font-size: 1rem;
  }

  .header .middle-content h1 {
    font-size: 1.375rem;
  }

  .header .middle-content .datestyle {
    font-size: 1.5rem;
  }

  .header .middle-content h3 {
    font-size: 1.125rem;
    padding: 0.375rem 1rem;
  }

  .header .middle-content .pay-card {
    height: 2.1875rem;
  }

  .sec-w h2,
  .sec-images h2,
  .faq-sec h2,
  .sponsored-sec h2 {
    font-size: 1.25rem;
  }

  .navbar-brand img {
    height: 3.75rem;
  }

  .logos-sec {
    margin-top: 0.3125rem;
  }

  .logos-sec img {
    height: 2.375rem;
  }

  .sec-images .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }
}
